// @mui material components
import { makeStyles } from "@mui/styles";

// Images
import pxToRem from "assets/theme/functions/pxToRem";

export default makeStyles(({ functions, palette, boxShadows }) => {
  const { linearGradient, rgba } = functions;
  const { gradients } = palette;
  const { xl } = boxShadows;

  console.log("style", linearGradient, rgba, gradients);
  const API_SERVER =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : "http://localhost:8085/";
  return {
    betflixPlayCard: {
      backgroundImage: () => `url(${API_SERVER}images/promotion/promotion_bg.png)`,
      backgroundRepeat: "cover",
      backgroundSize: "100% 100%",
      //   borderRadius: "lg",
      boxShadow: xl,
      backgroundColor: "transparent",
      position: "relative",
      overflow: "hidden",
      display: "flex",
    },

    betflixPlayCard_number: {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(40),
      paddingBottom: pxToRem(8),
    },
  };
});
