/* eslint-disable */
import PropTypes from "prop-types";
// import { useHistory } from "react-router-dom";
// import { Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";
import { Card, CircularProgress, Divider, Grid, Icon } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useEffect, useState } from "react";
import SuiButton from "components/SuiButton";
import bronze from "assets/png/bronze.png";
import silver from "assets/png/silver.png";
import gold from "assets/png/gold.png";
import platinum from "assets/png/platinum.png";
import diamond from "assets/png/diamond.png";
// import wallet from "../../../assets/png/wallet.png";

function MemberBalance({
  allBalance,
  isLoading,
  rank,
  onFetchCreditBalance,
  onFriendReferral,
  onCommission,
  onTranferOut,
}) {
  const [balance, setBalance] = useState(allBalance);
  const [imgRank, setImgRank] = useState(bronze);
  // const history = useHistory();

  let member = localStorage.getItem("user");
  member = JSON.parse(member);

  useEffect(() => {
    setBalance(allBalance);
    if (rank < 100000) {
      setImgRank(bronze);
    }
    if (rank > 100000 && rank <= 500000) {
      setImgRank(silver);
    }
    if (rank > 500000 && rank <= 1000000) {
      setImgRank(gold);
    }
    if (rank > 1000000 && rank <= 5000000) {
      setImgRank(platinum);
    }
    if (rank > 5000000) {
      setImgRank(diamond);
    }
  }, [allBalance, rank]);

  return (
    <Card
      style={{
        backgroundColor: "#0000002e",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <SuiBox display="flex" flexDirection="column" p={2} lineHeight={1.25}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiBox display="flex">
            {/* <SuiBox component="img" width="70px" src={wallet} alt="wheelBG" /> */}
            <SuiBox display="flex" alignItems="center">
              <SuiBox component="img" src={imgRank} height="48px" />
              <SuiBox display="flex" flexDirection="column" ml={2}>
                <SuiTypography
                  component="label"
                  variant="body2"
                  textColor="light"
                  fontWeight="light"
                  textGradient
                >
                  {member?.phone_number}
                </SuiTypography>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="white"
                >
                  {member?.username}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiTypography
                component="label"
                variant="body2"
                textColor="light"
                fontWeight="light"
                textGradient
              >
                เครดิต
              </SuiTypography>
              <SuiBox
                sx={{ cursor: "pointer" }}
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => onFetchCreditBalance()}
              >
                <Icon className=" text-white">sync</Icon>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              {isLoading ? (
                <CircularProgress size="2rem" />
              ) : (
                <SuiBox>
                  <SuiBox display="flex" justifyContent="flex-end">
                    <SuiTypography variant="h5" textColor="warning" fontWeight="light" textGradient>
                      <CountUp
                        end={balance?.credit_balance ?? 0}
                        duration={1}
                        separator=","
                        decimals="2"
                      />
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              fullWidth
              circular
              size="small"
              onClick={onFriendReferral}
            >
              แนะนำเพื่อน
            </SuiButton>
          </Grid>
          <Grid item xs={6}>
            <SuiButton
              variant="gradient"
              buttonColor="dark"
              fullWidth
              circular
              size="small"
              onClick={onCommission}
            >
              คอมมิชชั่น
            </SuiButton>
          </Grid>
          {/* <Grid item xs={12}>
            <SuiButton
              variant="outlined"
              buttonColor="white"
              fullWidth
              circular
              size="small"
              onClick={onTranferOut}
            >
              โยกเงินออก
            </SuiButton>
          </Grid> */}
        </Grid>
      </SuiBox>
    </Card>
    // <SuiBox px={2} lineHeight={1.25} textAlign="center">
    //   <SuiBox display="flex" justifyContent="space-between" textAlign="center">
    //     <SuiTypography variant="label" textColor="light" fontWeight="bold" textGradient>
    //       กระเป๋าเงินหลัก
    //     </SuiTypography>
    //     <SuiBox
    //       sx={{ cursor: "pointer" }}
    //       size="small"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={() => onFetchCreditBalance()}
    //     >
    //       <Icon className=" text-white">sync</Icon>
    //     </SuiBox>
    //   </SuiBox>
    //   <SuiBox>
    //     {isLoading ? (
    //       <CircularProgress size="2rem" />
    //     ) : (
    //       <SuiBox>
    //         <SuiBox>
    //           <SuiTypography variant="h1" textColor="light" fontWeight="bold" textGradient>
    //             <CountUp
    //               end={balance?.credit_balance ?? 0}
    //               duration={1}
    //               separator=","
    //               decimals="2"
    //             />
    //             <SuiTypography component="span" variant="h5" fontWeight="bold">
    //               ฿
    //             </SuiTypography>
    //           </SuiTypography>
    //         </SuiBox>
    //       </SuiBox>
    //     )}
    //   </SuiBox>
    // </SuiBox>
  );
}

MemberBalance.defaultProps = {
  allBalance: {},
  isLoading: false,
  rank: "",
  onFetchCreditBalance: () => {},
  onFriendReferral: () => {},
  onCommission: () => {},
  onTranferOut: () => {},
};

MemberBalance.propTypes = {
  isLoading: PropTypes.bool,
  allBalance: PropTypes.shape,
  rank: PropTypes.string,
  onFetchCreditBalance: PropTypes.func,
  onFriendReferral: PropTypes.func,
  onCommission: PropTypes.func,
  onTranferOut: PropTypes.func,
};

export default MemberBalance;
