/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  // Slider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { verifyCurrency } from "utils/validations";
import { BetflixTranfer, UfaTranfer } from "api/tranfer";
import BFLOGO from "../../assets/png/bf.png";
import UFALOGO from "../../assets/png/ufalogo.png";
import { formatter } from "utils/validations";

// const IOSSlider = styled(Slider)(({ theme }) => ({
//   color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
//   height: 2,
//   padding: "15px 0",

//   "& .MuiSlider-valueLabel": {
//     fontSize: 12,
//     fontWeight: "normal",
//     top: 0,
//     backgroundColor: "unset",
//     color: theme.palette.text.primary,
//     "&:before": {
//       display: "none",
//     },
//     "& *": {
//       background: "transparent",
//       color: theme.palette.mode === "dark" ? "#fff" : "#000",
//     },
//   },
//   "& .MuiSlider-track": {
//     border: "none",
//   },
//   "& .MuiSlider-rail": {
//     opacity: 0.5,
//     backgroundColor: "#bfbfbf",
//   },
//   "& .MuiSlider-markLabel": {
//     fontSize: 12,
//     fontWeight: "normal",
//   },
// }
// ));

function TranferPopup({ agent, balance, agentBalance, onFetchBalance, onClose }) {
  const [error, setError] = useState();
  // const [newBalance, setNewBalance] = useState(balance);
  const [value, setValue] = useState(0);
  const [type, setType] = useState("add");
  const [isDisable, setDisable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isOpen = true;

  // const classes = styles();

  console.log("TranferPopup-agentBalance", agentBalance, balance);

  const totalCredit =
    balance && balance >= 0
      ? parseFloat(balance) + parseFloat(agentBalance)
      : 0 + parseFloat(agentBalance);

  const maxCredit = totalCredit.toFixed(2);
  const bfCredit = parseFloat(agentBalance);

  const marks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: maxCredit,
      label: maxCredit,
    },
  ];

  console.log("TranferPopup-marks", marks);

  // useEffect(() => {
  //   setValue(agentBalance);
  //   setNewBalance(balance);
  // }, [balance, agentBalance]);

  const handleSetAmount = (event) => {
    setError();
    setDisable(false);
    const v = event.target.value;
    console.log("handleSetAmount-v", v);
    if (parseFloat(v) > maxCredit) {
      console.log("v > maxCredit", v, ">", maxCredit);
      setError("ยอดเงินไม่เพียงพอ");
      setDisable(true);
    }
    if (!verifyCurrency(v)) {
      setError("ตัวอย่าง 100, 100.50");
      setDisable(true);
    }
    setValue(v);
  };

  async function handleTranferCredit() {
    const payload = {
      type: type,
      credit: value,
    };
    // if (agentBalance === value) {
    //   onClose();
    //   return;
    // }
    // if (value > agentBalance) {
    //   const addCredit = parseFloat(value) - parseFloat(agentBalance);
    //   payload.type = "add";
    //   payload.credit = addCredit.toFixed(2);
    // }
    // if (value < agentBalance) {
    //   const removeCredit = parseFloat(agentBalance) - parseFloat(value);
    //   payload.type = "remove";
    //   payload.credit = removeCredit.toFixed(2);
    // }
    setDisable(true);
    setLoading(true);
    let tranfer = BetflixTranfer;
    if (agent === "ufa") {
      tranfer = UfaTranfer;
    }

    try {
      const response = await tranfer(payload);
      if (response?.data?.status === "success") {
        onFetchBalance();
        onClose();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  console.log("tranferpopup-agentBalance", agentBalance);
  console.log("tranferpopup-balance", balance);
  console.log("tranferpopup-agent", agent);

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
      fullWidth
      open={isOpen}
    >
      <Card>
        <SuiBox
          px={2}
          display="flex"
          // backgroundColor="dark"
          backgroundGradient
          alignItems="center"
          justifyContent="space-between"
        >
          <SuiBox p={2} display="flex" alignItems="center">
            <SuiTypography component="label" variant="h4" textColor="dark">
              โยกเงิน
            </SuiTypography>
            {agent && (
              <SuiTypography ml={2} component="label" variant="h4" textColor="dark">
                {"<->"}
              </SuiTypography>
            )}
            {agent === "ufa" && (
              <SuiBox ml={2} component="img" src={UFALOGO} alt="pattern-lines" width="20%" />
            )}
            {agent === "betflix" && (
              <SuiBox ml={2} component="img" src={BFLOGO} alt="pattern-lines" width="20%" />
            )}
          </SuiBox>
          <SuiButton
            // variant="outlined"
            buttonColor="dark"
            size="small"
            circular
            iconOnly
            onClick={() => onClose()}
          >
            <Icon className="" color="inherit">
              close
            </Icon>
          </SuiButton>
        </SuiBox>
        <SuiBox>
          <DialogContent>
            {isLoading ? (
              <SuiBox
                p={2}
                display="flex"
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <SuiTypography component="label" variant="caption" textColor="dark">
                  กำลังโยกเงิน
                </SuiTypography>
                <SuiBox ml={1}>
                  <CircularProgress size="2rem" />
                </SuiBox>
              </SuiBox>
            ) : (
              <SuiBox px={2}>
                <SuiBox display="flex">
                  <SuiTypography component="label" variant="body2" textColor="dark">
                    มีเครดิตจำนวน:{" "}
                    <SuiTypography component="label" variant="h5" textColor="dark">
                      {type === "add"
                        ? formatter.format(balance).toString()
                        : formatter.format(agentBalance).toString()}
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={1}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={type}
                    onChange={(t) => setType(t.target.value)}
                  >
                    <SuiBox display="flex">
                      <SuiBox>
                        <FormControlLabel value="add" control={<Radio />} label="โยกเงินเข้า" />
                      </SuiBox>
                      <SuiBox ml={2}>
                        <FormControlLabel value="remove" control={<Radio />} label="โยกเงินออก" />
                      </SuiBox>
                    </SuiBox>
                  </RadioGroup>
                </SuiBox>
                <SuiBox mt={1}>
                  <SuiTypography component="label" variant="caption" textColor="dark">
                    จำนวนเงินที่ต้องการโยก
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <SuiInput
                    value={value}
                    onChange={(event) => handleSetAmount(event)}
                    placeholder="จำนวน"
                  />
                </SuiBox>
                <SuiBox display="inline">
                  <SuiTypography mt={1} fontSize={12} textColor="error">
                    {error ?? ""}
                  </SuiTypography>
                </SuiBox>
                <SuiBox display="flex" flexDirection="column" mt={2}>
                  {/* <IOSSlider
                    aria-label="ios slider"
                    value={value}
                    defaultValue={bfCredit}
                    min={0}
                    max={maxCredit}
                    marks={marks}
                    onChange={(event) => handleSetAmount(event)}
                  /> */}
                  {/* <SuiBox display="flex" justifyContent="space-between">
                    <SuiTypography
                      ml={-3}
                      component="label"
                      variant="caption"
                      textColor="secondary"
                    >
                      โยกเงินออก
                    </SuiTypography>
                    <SuiTypography
                      mr={-3}
                      component="label"
                      variant="caption"
                      textColor="secondary"
                    >
                      โยกเงินเข้า
                    </SuiTypography>
                  </SuiBox> */}
                </SuiBox>
              </SuiBox>
            )}
          </DialogContent>
          {!isLoading && (
            <DialogActions>
              <SuiButton
                disabled={isDisable}
                variant="gradient"
                buttonColor="info"
                onClick={() => handleTranferCredit()}
              >
                ยืนยัน
              </SuiButton>
            </DialogActions>
          )}
        </SuiBox>
      </Card>
    </Dialog>
  );
}

TranferPopup.defaultProps = {
  agent: "",
  balance: "",
  agentBalance: "",
  onFetchBalance: () => {},
  onClose: () => {},
};

TranferPopup.propTypes = {
  agent: PropTypes.string,
  balance: PropTypes.string,
  agentBalance: PropTypes.string,
  onFetchBalance: PropTypes.func,
  onClose: PropTypes.func,
};

export default TranferPopup;
