/* eslint-disable */
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for ComplexBackgroundCard
import styles from "examples/Cards/BackgroundCards/ComplexBackgroundCard/styles";

function ComplexBackgroundCard({ color, image, description }) {
  const classes = styles({ color, image });

  return (
    <Card className={classes.complexBackgroundCard} style={{ width: "50%" }}>
      <SuiBox textAlign="center" p={2}>
        <SuiBox display="flex" flexDirection="column" mt={6} p={3}>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" textColor="white" fontWeight="bold">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of ComplexBackgroundCard
ComplexBackgroundCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the ComplexBackgroundCard
ComplexBackgroundCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  image: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ComplexBackgroundCard;
