/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { BottomNavigation, Paper } from "@mui/material";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";
import chipcolor from "assets/png/chip-color.png";
import home from "assets/png/home_2.png";
import game from "assets/png/game.png";
import gameA from "assets/png/game_a.png";
import bonus from "assets/png/bonus.png";
import deposit from "assets/png/deposit.png";
import depositA from "assets/png/deposit_a.png";
import withdraw from "assets/png/withdraw.png";
import withdrawA from "assets/png/withdraw_a.png";
import promotion from "assets/png/promotion.png";
import promotionA from "assets/png/promotion_a.png";
import profile from "assets/png/profile.png";
import profileA from "assets/png/profile_a.png";
import MemberProfilePopup from "../info/member-profile-popup";
import MONEYBAG from "../../../assets/png/money-bag2.png";
import USER from "../../../assets/png/user.png";

const API_SERVER =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_LANDIGNPAGE_POMOTION_PAGE
    : process.env.REACT_APP_LANDIGNPAGE_POMOTION_PAGE;

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: #dd00ff;
  }
`);

function BottomMenus({ page }) {
  const [value, setValue] = useState(page);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const history = useHistory();

  console.log("BottomMenus-page", page);

  function handleCloseProfile() {
    setProfileOpen(false);
    if (history?.location?.pathname === "/") {
      setValue(2);
    }
    if (history?.location?.pathname === "/deposit") {
      setValue(0);
    }
    if (history?.location?.pathname === "/withdraw") {
      setValue(1);
    }
  }

  function handleSetValue(e, newValue) {
    // setValue(newValue);
    if (newValue === 2) {
      setValue(2);
      history.push(`/`);
    }
    if (newValue === 0) {
      setValue(0);
      history.push(`/deposit`);
    }
    if (newValue === 1) {
      setValue(1);
      history.push(`/withdraw`);
    }
    if (newValue === 3) {
      setValue(3);
      history.push(`/promotions`);
    }
    if (newValue === 4) {
      setValue(4);
      setProfileOpen(true);
    }
  }

  useEffect(() => {
    console.log("BottomMenus-history", history);

    if (history?.location?.pathname === "/deposit") {
      setValue(0);
    } else if (history?.location?.pathname === "/withdraw") {
      setValue(1);
    } else if (
      history?.location?.pathname === "/promotions" ||
      history?.location?.pathname === "/promotion/wheel"
    ) {
      setValue(3);
    } else if (history?.location?.pathname === "/" || history?.location?.pathname === "") {
      setValue(2);
    } else {
      setValue(null);
    }
  }, [page]);

  return (
    <Paper
      sx={{
        position: "fixed",
        zIndex: 99,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
        height: "65px",
      }}
      elevation={3}
    >
      {isProfileOpen && <MemberProfilePopup onClose={() => handleCloseProfile()} />}
      <BottomNavigation
        sx={{
          height: "65px",
          background: "rgba(0, 0, 0, 0.7)",
        }}
        showLabels
        value={value}
        onChange={(e, newValue) => handleSetValue(e, newValue)}
      >
        <BottomNavigationAction
          label="ฝากเงิน"
          icon={
            value === 0 ? (
              <SuiBox
                mt={-3}
                mb={1}
                component="img"
                src={depositA}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3rem"
                height="3rem"
                customClass="cursor-pointer"
              />
            ) : (
              <SuiBox
                component="img"
                src={deposit}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
              />
            )
          }
        />
        <BottomNavigationAction
          label="ถอนเงิน"
          icon={
            value === 1 ? (
              <SuiBox
                mt={-3}
                mb={1}
                component="img"
                src={withdrawA}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3rem"
                height="3rem"
                customClass="cursor-pointer"
              />
            ) : (
              <SuiBox
                component="img"
                src={withdraw}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
              />
            )
          }
        />
        <BottomNavigationAction
          label="หน้าหลัก"
          sx={{ color: "white" }}
          icon={
            value === 2 ? (
              <SuiBox
                mt={-3}
                mb={1}
                component="img"
                src={gameA}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3rem"
                height="3rem"
                customClass="cursor-pointer"
              />
            ) : (
              <SuiBox
                component="img"
                src={game}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
              />
            )
          }
        />

        <BottomNavigationAction
          label="โปรโมชั่น"
          icon={
            value === 3 ? (
              <SuiBox
                mt={-3}
                mb={1}
                component="img"
                src={promotionA}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3rem"
                height="3rem"
                customClass="cursor-pointer"
              />
            ) : (
              <SuiBox
                component="img"
                src={promotion}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
              />
            )
          }
        />
        <BottomNavigationAction
          label="โปรไฟล์"
          icon={
            value === 4 ? (
              <SuiBox
                mt={-3}
                mb={1}
                component="img"
                src={profileA}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3rem"
                height="3rem"
                customClass="cursor-pointer"
              />
            ) : (
              <SuiBox
                component="img"
                src={profile}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
              />
            )
          }
        />
      </BottomNavigation>
    </Paper>
  );
}

BottomMenus.defaultProps = {
  page: 1,
};

BottomMenus.propTypes = {
  page: PropTypes.number,
};

export default BottomMenus;
