export const PGs = [
  {
    id: 759717,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Honey Trap of Diao Chan",
    subAgtName: "pg9win",
    gameCode: "1",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1.png",
    gameId: 169701,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 0.3], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759718,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Gem Saviour",
    subAgtName: "pg9win",
    gameCode: "2",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/2.png",
    gameId: 169702,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 1, 6, 20], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759719,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Gods",
    subAgtName: "pg9win",
    gameCode: "3",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/3.png",
    gameId: 169703,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.06, 0.6, 6], "defBetSize": 0.06, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759720,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Medusa II",
    subAgtName: "pg9win",
    gameCode: "6",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/6.png",
    gameId: 169704,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 1, 10], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759721,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Medusa",
    subAgtName: "pg9win",
    gameCode: "7",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/7.png",
    gameId: 169705,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759722,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Hood vs Wolf",
    subAgtName: "pg9win",
    gameCode: "18",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/18.png",
    gameId: 169706,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 1, 10], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759723,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Reel Love",
    subAgtName: "pg9win",
    gameCode: "20",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/20.png",
    gameId: 169707,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759724,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Win Win Won",
    subAgtName: "pg9win",
    gameCode: "24",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/24.png",
    gameId: 169708,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.5, 3, 10, 30], "defBetSize": 3.0, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759725,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Plushie Frenzy",
    subAgtName: "pg9win",
    gameCode: "25",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/25.png",
    gameId: 169709,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 3], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759726,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Tree of Fortune",
    subAgtName: "pg9win",
    gameCode: "26",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/26.png",
    gameId: 169710,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1], "defBetSize": 1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759727,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Hotpot",
    subAgtName: "pg9win",
    gameCode: "28",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/28.png",
    gameId: 169711,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.03, 0.1, 1, 10], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759728,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Dragon Legend",
    subAgtName: "pg9win",
    gameCode: "29",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/29.png",
    gameId: 169712,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.1, 1, 10], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759729,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Baccarat Deluxe",
    subAgtName: "pg9win",
    gameCode: "31",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/31.png",
    gameId: 169713,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1], "defBetSize": 1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759730,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Hip Hop Panda",
    subAgtName: "pg9win",
    gameCode: "33",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/33.png",
    gameId: 169714,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 3], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759731,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Legend of Hou Yi ",
    subAgtName: "pg9win",
    gameCode: "34",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/34.png",
    gameId: 169715,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759732,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mr. Hallow-Win",
    subAgtName: "pg9win",
    gameCode: "35",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/35.png",
    gameId: 169716,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 3], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759733,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Prosperity Lion",
    subAgtName: "pg9win",
    gameCode: "36",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/36.png",
    gameId: 169717,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.5, 5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759734,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Santa's Gift Rush",
    subAgtName: "pg9win",
    gameCode: "37",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/37.png",
    gameId: 169718,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759735,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Gem Saviour Sword",
    subAgtName: "pg9win",
    gameCode: "38",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/38.png",
    gameId: 169719,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.5, 5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759736,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Piggy Gold",
    subAgtName: "pg9win",
    gameCode: "39",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/39.png",
    gameId: 169720,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.25, 1, 5, 20, 40], "defBetSize": 1.0, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759737,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Jungle Delight",
    subAgtName: "pg9win",
    gameCode: "40",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/40.png",
    gameId: 169721,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 3], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759738,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Symbols of Egypt",
    subAgtName: "pg9win",
    gameCode: "41",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/41.png",
    gameId: 169722,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1], "defBetSize": 1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759739,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ganesha Gold",
    subAgtName: "pg9win",
    gameCode: "42",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/42.png",
    gameId: 169723,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 0.3, 0.6], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759740,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Emperor's Favour",
    subAgtName: "pg9win",
    gameCode: "44",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/44.png",
    gameId: 169724,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 0.3], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759741,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Double Fortune",
    subAgtName: "pg9win",
    gameCode: "48",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/48.png",
    gameId: 169725,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.2, 0.6, 1.6], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759742,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Journey to the Wealth",
    subAgtName: "pg9win",
    gameCode: "50",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/50.png",
    gameId: 169726,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.2, 0.9], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759743,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "The Great Icescape",
    subAgtName: "pg9win",
    gameCode: "53",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/53.png",
    gameId: 169727,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.02, 0.1, 0.6], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759744,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Captain's Bounty",
    subAgtName: "pg9win",
    gameCode: "54",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/54.png",
    gameId: 169728,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759745,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Dragon Hatch",
    subAgtName: "pg9win",
    gameCode: "57",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/57.png",
    gameId: 169729,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5, 10], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759746,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Vampire's Charm",
    subAgtName: "pg9win",
    gameCode: "58",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/58.png",
    gameId: 169730,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.2, 1, 5], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759747,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ninja vs Samurai ",
    subAgtName: "pg9win",
    gameCode: "59",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/59.png",
    gameId: 169731,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759748,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Leprechaun Riches",
    subAgtName: "pg9win",
    gameCode: "60",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/60.png",
    gameId: 169732,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 0.3, 2.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759749,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Flirting Scholar",
    subAgtName: "pg9win",
    gameCode: "61",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/61.png",
    gameId: 169733,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 3], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759750,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Gem Saviour Conquest",
    subAgtName: "pg9win",
    gameCode: "62",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/62.png",
    gameId: 169734,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.2, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759751,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Dragon Tiger Luck",
    subAgtName: "pg9win",
    gameCode: "63",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/63.png",
    gameId: 169735,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 1, 10, 100], "defBetSize": 1.0, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759752,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Muay Thai Champion",
    subAgtName: "pg9win",
    gameCode: "64",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/64.png",
    gameId: 169736,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.3, 1.5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759753,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mahjong Ways",
    subAgtName: "pg9win",
    gameCode: "65",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/65.png",
    gameId: 169737,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 0.03, 0.1, 1, 5], "defBetSize": 0.03, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759754,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Shaolin Soccer",
    subAgtName: "pg9win",
    gameCode: "67",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/67.png",
    gameId: 169738,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 2], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759755,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Mouse",
    subAgtName: "pg9win",
    gameCode: "68",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/68.png",
    gameId: 169739,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.01, 1, 10], "defBetSize": 1.0, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759756,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Bikini Paradise",
    subAgtName: "pg9win",
    gameCode: "69",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/69.png",
    gameId: 169740,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.8], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759757,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Candy Burst",
    subAgtName: "pg9win",
    gameCode: "70",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/70.png",
    gameId: 169741,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 2.5, 5], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759758,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Caishen Wins",
    subAgtName: "pg9win",
    gameCode: "71",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/71.png",
    gameId: 169742,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759759,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Egypt's Book of Mystery",
    subAgtName: "pg9win",
    gameCode: "73",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/73.png",
    gameId: 169743,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759760,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mahjong Ways 2",
    subAgtName: "pg9win",
    gameCode: "74",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/74.png",
    gameId: 169744,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 1, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759761,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ganesha Fortune",
    subAgtName: "pg9win",
    gameCode: "75",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/75.png",
    gameId: 169745,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759762,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Dreams of Macau",
    subAgtName: "pg9win",
    gameCode: "79",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/79.png",
    gameId: 169746,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759763,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Circus Delight",
    subAgtName: "pg9win",
    gameCode: "80",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/80.png",
    gameId: 169747,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759764,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Phoenix Rises ",
    subAgtName: "pg9win",
    gameCode: "82",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/82.png",
    gameId: 169748,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 1, 5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759765,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Fireworks",
    subAgtName: "pg9win",
    gameCode: "83",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/83.png",
    gameId: 169749,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759766,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Queen of Bounty",
    subAgtName: "pg9win",
    gameCode: "84",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/84.png",
    gameId: 169750,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759767,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Genie's 3 Wishes",
    subAgtName: "pg9win",
    gameCode: "85",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/85.png",
    gameId: 169751,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759768,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Galactic Gems",
    subAgtName: "pg9win",
    gameCode: "86",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/86.png",
    gameId: 169752,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759769,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Treasures of Aztec",
    subAgtName: "pg9win",
    gameCode: "87",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/87.png",
    gameId: 169753,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759770,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Jewels of Prosperity",
    subAgtName: "pg9win",
    gameCode: "88",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/88.png",
    gameId: 169754,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759771,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Lucky Neko",
    subAgtName: "pg9win",
    gameCode: "89",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/89.png",
    gameId: 169755,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759772,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Secrets of Cleopatra",
    subAgtName: "pg9win",
    gameCode: "90",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/90.png",
    gameId: 169756,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.2, 1, 5], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 6}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759773,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Guardians of Ice and Fire",
    subAgtName: "pg9win",
    gameCode: "91",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/91.png",
    gameId: 169757,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759774,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Thai River Wonders",
    subAgtName: "pg9win",
    gameCode: "92",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/92.png",
    gameId: 169758,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759775,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Opera Dynasty",
    subAgtName: "pg9win",
    gameCode: "93",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/93.png",
    gameId: 169759,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759776,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Bali Vacation",
    subAgtName: "pg9win",
    gameCode: "94",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/94.png",
    gameId: 169760,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5, 10], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759777,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Majestic Treasures",
    subAgtName: "pg9win",
    gameCode: "95",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/95.png",
    gameId: 169761,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5, 10], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759778,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Jack Frost's Winter",
    subAgtName: "pg9win",
    gameCode: "97",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/97.png",
    gameId: 169762,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5, 10], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759779,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Ox",
    subAgtName: "pg9win",
    gameCode: "98",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/98.png",
    gameId: 169763,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759780,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Candy Bonanza",
    subAgtName: "pg9win",
    gameCode: "100",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/100.png",
    gameId: 169764,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759781,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Rise of Apollo",
    subAgtName: "pg9win",
    gameCode: "101",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/101.png",
    gameId: 169765,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759782,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mermaid Riches",
    subAgtName: "pg9win",
    gameCode: "102",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/102.png",
    gameId: 169766,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759783,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Crypto Gold",
    subAgtName: "pg9win",
    gameCode: "103",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/103.png",
    gameId: 169767,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759784,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Bandito",
    subAgtName: "pg9win",
    gameCode: "104",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/104.png",
    gameId: 169768,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759785,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Heist Stakes",
    subAgtName: "pg9win",
    gameCode: "105",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/105.png",
    gameId: 169769,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759786,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ways of the Qilin",
    subAgtName: "pg9win",
    gameCode: "106",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/106.png",
    gameId: 169770,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759787,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Legendary Monkey King",
    subAgtName: "pg9win",
    gameCode: "107",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/107.png",
    gameId: 169771,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759788,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Buffalo Win",
    subAgtName: "pg9win",
    gameCode: "108",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/108.png",
    gameId: 169772,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759789,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Jurassic Kingdom",
    subAgtName: "pg9win",
    gameCode: "110",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/110.png",
    gameId: 169773,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759790,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Oriental Prosperity",
    subAgtName: "pg9win",
    gameCode: "112",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/112.png",
    gameId: 169774,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759791,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Raider Jane's Crypt of Fortune",
    subAgtName: "pg9win",
    gameCode: "113",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/113.png",
    gameId: 169775,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759792,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Emoji Riches",
    subAgtName: "pg9win",
    gameCode: "114",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/114.png",
    gameId: 169776,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759793,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Supermarket Spree",
    subAgtName: "pg9win",
    gameCode: "115",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/115.png",
    gameId: 169777,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759794,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Cocktail Nights",
    subAgtName: "pg9win",
    gameCode: "117",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/117.png",
    gameId: 169778,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759795,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mask Carnival",
    subAgtName: "pg9win",
    gameCode: "118",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/118.png",
    gameId: 169779,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759796,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Spirited Wonders",
    subAgtName: "pg9win",
    gameCode: "119",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/119.png",
    gameId: 169780,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759797,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "The Queen's Banquet",
    subAgtName: "pg9win",
    gameCode: "120",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/120.png",
    gameId: 169781,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759798,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Destiny of Sun & Moon",
    subAgtName: "pg9win",
    gameCode: "121",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/121.png",
    gameId: 169782,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759799,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Garuda Gems",
    subAgtName: "pg9win",
    gameCode: "122",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/122.png",
    gameId: 169783,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759800,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Rooster Rumble",
    subAgtName: "pg9win",
    gameCode: "123",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/123.png",
    gameId: 169784,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759801,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Battleground Royale",
    subAgtName: "pg9win",
    gameCode: "124",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/124.png",
    gameId: 169785,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759802,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Butterfly Blossom",
    subAgtName: "pg9win",
    gameCode: "125",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/125.png",
    gameId: 169786,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759803,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Tiger",
    subAgtName: "pg9win",
    gameCode: "126",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/126.png",
    gameId: 169787,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.1, 1, 5, 20], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759804,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Speed Winner",
    subAgtName: "pg9win",
    gameCode: "127",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/127.png",
    gameId: 169788,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759805,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Legend of Perseus",
    subAgtName: "pg9win",
    gameCode: "128",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/128.png",
    gameId: 169789,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759806,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Win Win Fish Prawn Crab",
    subAgtName: "pg9win",
    gameCode: "129",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/129.png",
    gameId: 169790,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759807,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Lucky Piggy",
    subAgtName: "pg9win",
    gameCode: "130",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/130.png",
    gameId: 169791,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759808,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Coaster",
    subAgtName: "pg9win",
    gameCode: "132",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/132.png",
    gameId: 169792,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759809,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Bounty Showdown",
    subAgtName: "pg9win",
    gameCode: "135",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/135.png",
    gameId: 169793,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759810,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Prosperity Fortune Tree",
    subAgtName: "pg9win",
    gameCode: "1312883",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1312883.png",
    gameId: 169794,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759811,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Totem Wonders",
    subAgtName: "pg9win",
    gameCode: "1338274",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1338274.png",
    gameId: 169795,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759812,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Asgardian Rising",
    subAgtName: "pg9win",
    gameCode: "1340277",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1340277.png",
    gameId: 169796,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759813,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Alchemy Gold",
    subAgtName: "pg9win",
    gameCode: "1368367",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1368367.png",
    gameId: 169797,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759814,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Diner Delights",
    subAgtName: "pg9win",
    gameCode: "1372643",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1372643.png",
    gameId: 169798,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759815,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Hawaiian Tiki",
    subAgtName: "pg9win",
    gameCode: "1381200",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1381200.png",
    gameId: 169799,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759816,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fruity Candy",
    subAgtName: "pg9win",
    gameCode: "1397455",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1397455.png",
    gameId: 169800,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759817,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Midas Fortune",
    subAgtName: "pg9win",
    gameCode: "1402846",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1402846.png",
    gameId: 169801,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759818,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Bakery Bonanza",
    subAgtName: "pg9win",
    gameCode: "1418544",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1418544.png",
    gameId: 169802,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759819,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Rave Party Fever",
    subAgtName: "pg9win",
    gameCode: "1420892",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1420892.png",
    gameId: 169803,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759820,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mystical Spirits",
    subAgtName: "pg9win",
    gameCode: "1432733",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1432733.png",
    gameId: 169804,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759821,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Songkran Splash",
    subAgtName: "pg9win",
    gameCode: "1448762",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1448762.png",
    gameId: 169805,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759822,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Dragon Hatch 2",
    subAgtName: "pg9win",
    gameCode: "1451122",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1451122.png",
    gameId: 169806,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759823,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Cruise Royale",
    subAgtName: "pg9win",
    gameCode: "1473388",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1473388.png",
    gameId: 169807,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759824,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ultimate Striker",
    subAgtName: "pg9win",
    gameCode: "1489936",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1489936.png",
    gameId: 169808,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759825,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Pinata Wins",
    subAgtName: "pg9win",
    gameCode: "1492288",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1492288.png",
    gameId: 169809,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759826,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Ape",
    subAgtName: "pg9win",
    gameCode: "1508783",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1508783.png",
    gameId: 169810,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1], "defBetSize": 0.2, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 2}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759827,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Super Golf Drive",
    subAgtName: "pg9win",
    gameCode: "1513328",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1513328.png",
    gameId: 169811,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759828,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Ninja Racoon Frenzy",
    subAgtName: "pg9win",
    gameCode: "1529867",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1529867.png",
    gameId: 169812,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759829,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Rabbit",
    subAgtName: "pg9win",
    gameCode: "1543462",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1543462.png",
    gameId: 169813,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 5], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759830,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Forge of Wealth",
    subAgtName: "pg9win",
    gameCode: "1555350",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1555350.png",
    gameId: 169814,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759831,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Wild Heist Cashout",
    subAgtName: "pg9win",
    gameCode: "1568554",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1568554.png",
    gameId: 169815,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759832,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Gladiator's Glory",
    subAgtName: "pg9win",
    gameCode: "1572362",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1572362.png",
    gameId: 169816,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759833,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mafia Mayhem",
    subAgtName: "pg9win",
    gameCode: "1580541",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1580541.png",
    gameId: 169817,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759834,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Safari Wilds",
    subAgtName: "pg9win",
    gameCode: "1594259",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1594259.png",
    gameId: 169818,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759835,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Lucky Clover Lady",
    subAgtName: "pg9win",
    gameCode: "1601012",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1601012.png",
    gameId: 169819,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759836,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Werewolf's Hunt",
    subAgtName: "pg9win",
    gameCode: "1615454",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1615454.png",
    gameId: 169820,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759837,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Tsar Treasures",
    subAgtName: "pg9win",
    gameCode: "1655268",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1655268.png",
    gameId: 169821,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759838,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Gemstones Gold",
    subAgtName: "pg9win",
    gameCode: "1671262",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1671262.png",
    gameId: 169822,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759839,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Cash Mania",
    subAgtName: "pg9win",
    gameCode: "1682240",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1682240.png",
    gameId: 169823,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 10], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759840,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Fortune Dragon",
    subAgtName: "pg9win",
    gameCode: "1695365",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1695365.png",
    gameId: 169824,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.1, 1, 5, 20], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 10}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759841,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Mystic Potion",
    subAgtName: "pg9win",
    gameCode: "1717688",
    gameThumbnail:
      "https://ab-prod-s3-saas.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1717688.png",
    gameId: 169951,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759842,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Anubis Wrath",
    subAgtName: "pg9win",
    gameCode: "1623475",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1623475.png",
    gameId: 238992,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP赢率", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.1, 0.5, 2.5], "defBetSize": 0.1, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 3}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759846,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Zombie Outbreak",
    subAgtName: "pg9win",
    gameCode: "1635221",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA_xpg_electronic/en/1635221.png",
    gameId: 789232,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759848,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Chicken Run",
    subAgtName: "pg9win",
    gameCode: "1738001",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/games/pg_electronic/en/1738001.png",
    gameId: 789233,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759845,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Futebol Fever",
    subAgtName: "pg9win",
    gameCode: "1778752",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/games/pg_electronic/en/1778752.png",
    gameId: 789234,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759844,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "Shark Bounty",
    subAgtName: "pg9win",
    gameCode: "1648578",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/games/pg_electronic/en/1648578.png",
    gameId: 789235,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
  {
    id: 759847,
    cloudId: null,
    groupId: 60437,
    groupCode: "RA_xpg_electronic",
    coinName: null,
    gameName: "YYakuza Honor",
    subAgtName: "pg9win",
    gameCode: "1760238",
    gameThumbnail:
      "https://saas-s3-prod.s3-accelerate.amazonaws.com/games/pg_electronic/en/1760238.png",
    gameId: 789236,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "label": "RTP", "value": 97, "maxVal": 98, "minVal": 50, "component": "inputNumber"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 690070,
    updatedTime: 1730274413000,
    updatedByName: "bx29",
  },
];
