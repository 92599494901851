export const XGs = [
  {
    id: 107431,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Candy Crush",
    subAgtName: "PG9WIN",
    gameCode: "SugarRush",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/SugarRush-1706674526757.png",
    gameId: 191867,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP值", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725193000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107433,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Luckywheel",
    subAgtName: "PG9WIN",
    gameCode: "WheelOfTime",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/WheelOfTime-1706674628298.png",
    gameId: 191857,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP值", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725177000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107447,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "MatchDay",
    subAgtName: "PG9WIN",
    gameCode: "MatchDay",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/MatchDay-1706674541023.png",
    gameId: 191847,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725166000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107441,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "MarioFruit",
    subAgtName: "PG9WIN",
    gameCode: "MarioFruit",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/MarioFruit-1706674673033.png",
    gameId: 191844,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725157000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107772,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Limbo Magic",
    subAgtName: "PG9WIN",
    gameCode: "Limbo2",
    gameThumbnail: "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA/en/Limbo2.png",
    gameId: 248851,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["50", "88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725148000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107448,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Hilo",
    subAgtName: "PG9WIN",
    gameCode: "Hilo",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Hilo-1706674552593.png",
    gameId: 191859,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725138000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107446,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "FortuneRabbit",
    subAgtName: "PG9WIN",
    gameCode: "FortuneRabbit",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/FortuneRabbit-1706674567853.png",
    gameId: 191868,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725127000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107434,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Hatch",
    subAgtName: "PG9WIN",
    gameCode: "Hatch",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Hatch-1706674502507.png",
    gameId: 191851,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP值", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725112000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107432,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "GemBonanza",
    subAgtName: "PG9WIN",
    gameCode: "GemBonanza",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/GemBonanza-1706674581276.png",
    gameId: 191841,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP值", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725084000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107445,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Dice",
    subAgtName: "PG9WIN",
    gameCode: "Dice",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Dice-1706674389349.png",
    gameId: 191842,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725071000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107444,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Limbo",
    subAgtName: "PG9WIN",
    gameCode: "Limbo",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Limbo-1706674642949.png",
    gameId: 191845,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "101"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.05, 0.5, 2, 4], "defBetSize": 0.05, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725725011000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107773,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "MatchDay Arena",
    subAgtName: "PG9WIN",
    gameCode: "MatchDay2",
    gameThumbnail: "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA/en/MatchDay2.png",
    gameId: 248852,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["50", "88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1.0], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107439,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Mines",
    subAgtName: "PG9WIN",
    gameCode: "Mines",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Mines-1706674447117.png",
    gameId: 191848,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1.0], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107771,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Mines Snowfield",
    subAgtName: "PG9WIN",
    gameCode: "Mines2",
    gameThumbnail: "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA/en/Mines2.png",
    gameId: 248853,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["50", "88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1.0], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107443,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Rat Rat Fu Fu",
    subAgtName: "PG9WIN",
    gameCode: "Mouses",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Mouses-1706674616449.png",
    gameId: 191840,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1.0], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: 107440,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Plinko",
    subAgtName: "PG9WIN",
    gameCode: "Plinko",
    gameThumbnail:
      "https://newbit-staging-s3-saas.s3-accelerate.amazonaws.com/Plinko-1706674468091.png",
    gameId: 191863,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["88", "90", "92", "95", "97", "99", "100", "103", "105"], "component": "select"}]',
    betSettings:
      '{"THB": {"betSizes": [0.02, 0.2, 1.0], "defBetSize": 0.02, "betMultipliers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "defBetMultiplier": 1}}',
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: 109909,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Locomotive Queens",
    subAgtName: "PG9WIN",
    gameCode: "Queens",
    gameThumbnail: "https://saas-s3-prod.s3-accelerate.amazonaws.com/ra/games/RA/cn/Queens.jpeg",
    gameId: 275984,
    statusCd: "enable",
    cocosGameStatusCd: "enable",
    maintenanceStatusCd: "normal",
    configProperty:
      '[{"key": "rtp", "tips": "RTP", "label": "RTP", "value": "88", "options": ["50", "88", "90", "92", "95", "97", "99", "100", "102", "103"], "component": "select"}]',
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: 600173,
    updatedTime: 1725702429000,
    updatedByName: "PG9WIN",
  },
  {
    id: null,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Hash Battle,TRON 5m",
    subAgtName: "PG9WIN",
    gameCode: "hash_fight_60027",
    gameThumbnail:
      "https://newchain-prod-s3-saas.s3-accelerate.amazonaws.com/%E5%93%88%E5%B8%8C%E5%A4%A7%E4%BD%9C%E6%88%98-1702957616234.png",
    gameId: 191866,
    statusCd: null,
    cocosGameStatusCd: null,
    maintenanceStatusCd: null,
    configProperty: null,
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: null,
    updatedTime: null,
    updatedByName: null,
  },
  {
    id: null,
    cloudId: null,
    groupId: 30479,
    groupCode: "RA",
    coinName: null,
    gameName: "Hash Battle,TRON 4m",
    subAgtName: "PG9WIN",
    gameCode: "hash_fight_60026",
    gameThumbnail:
      "https://newchain-prod-s3-saas.s3-accelerate.amazonaws.com/%E5%93%88%E5%B8%8C%E5%A4%A7%E4%BD%9C%E6%88%98-1702957590307.png",
    gameId: 191843,
    statusCd: null,
    cocosGameStatusCd: null,
    maintenanceStatusCd: null,
    configProperty: null,
    betSettings: null,
    currentPrizePool: null,
    segmentsPrizePoolMap: null,
    isShowPrizePool: false,
    updateById: null,
    updatedTime: null,
    updatedByName: null,
  },
];
