/* eslint-disable */
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import { Card, Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import breakpoints from "assets/theme/base/breakpoints";
import appbarlogo from "assets/png/appbarlogo.png";
import { GETPROMOTIONLIST } from "api/util";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import styles from "./styles";
import promotionWheel from "assets/jpg/promotion_wheel.jpg";
import "../../../fonts/DB_Helvethaica_X.ttf";

const API_SERVER =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL_AGENT
    : "http://localhost:8080/";

function PromotionList() {
  const classes = styles();
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [imgPro, setImgPro] = useState([]);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  const getpromotionlist = async () => {
    Swal.fire({
      title: "Loading ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await GETPROMOTIONLIST();
      Swal.close();
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        console.log("getpromotionlist", data, response);
        setPromotions(data);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  function importAll(r) {
    console.log("importAll-r", r.keys());

    return r.keys().map(r);
  }

  useEffect(() => {
    console.log("useEffect");
    const loadPropic = importAll(
      require.context("../../../assets/promotions", false, /\.(png|jpe?g|svg)$/)
    );
    setImgPro(loadPropic);
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    getpromotionlist();
  }, []);

  return (
    <SuiBox>
      <SuiBox mb={1}>
        <SuiTypography variant="h2" textColor="light" textGradient>
          โปรโมชั่นสำหรับสมาชิก
        </SuiTypography>
      </SuiBox>

      {/* <SuiBox
          sx={{ cursor: "pointer" }}
          onClick={() => history.push(`/promotion/wheel`)}
          component="img"
          src={promotionWheel}
          alt="prmotionWheel"
          width="100%"
        /> */}
      <SuiBox>
        <Grid container spacing={3}>
          {imgPro &&
            imgPro.length > 0 &&
            imgPro.map((p) => {
              return (
                <Grid item xs={12} lg={6}>
                  <SuiBox>
                    <SuiBox
                      component="img"
                      src={`${window.location.origin}${p.default}`}
                      alt={p.default}
                      borderRadius="lg"
                      width="100%"
                      display="inherit"
                      // height="50vh"
                    />
                  </SuiBox>
                </Grid>
              );
            })}
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default PromotionList;
