/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { create } from "jss";
import rtl from "jss-rtl";
import { Card, Dialog, DialogContent } from "@mui/material";
import { StylesProvider, jssPreset } from "@mui/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import routes from "routes";
import { useSoftUIController } from "context";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import ProtectedRoute from "components/ProtectedRoute/index";
import axios from "./api/index";
import LINEFAB from "./assets/png/LINEFAB.png";
import LuckyWheel from "./assets/gif/lucky-wheel.gif";
import SuiTypography from "components/SuiTypography";
// import AnnouncementPopup from "components/AnnouncementPopup";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  // eslint-disable-next-line
  const { miniSidenav, direction, layout, openConfigurator } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const history = useHistory();
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
  // JSS presets for the rtl
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      prepend: true,
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: true });
      setOnMouseEnter(false);
    }
  };

  // // Change the openConfigurator state
  // const handleConfiguratorOpen = () => {
  //   dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  // };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);

    // Swal.fire({
    //   title: "📢  ประกาศสำคัญ BETFLIX29",
    //   icon: "info",
    //   html: `ขณะนี้เว็ปไซด์ปิดปรับปรุงระบบ คุณลูกค้าจะไม่สามารถทำรายการ ฝาก-ถอน ได้ในขณะนี้
    //   จนกว่าระบบจะอัพเดทเสร็จสมบูรณ์ จะรีบแจ้งให้ทราบค่ะ`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   showCancelButton: false,
    //   // showConfirmButton: false,
    // });
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;
  const accessToken = localStorage.getItem("accessToken");
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        const Component = route.component;
        if (route.protected || route.route.protected) {
          return (
            <ProtectedRoute exact path={route.route} component={route.component} key={route.key} />
          );
        }
        return (
          <Route
            exact
            path={route.route}
            render={() => <Component message="hi" />}
            key={route.key}
          />
        );
      }

      return null;
    });

  const wheelButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      right="0.75rem"
      bottom={user && user?.token ? "10rem" : "0.75rem"}
      zIndex={99}
      customClass="cursor-pointer"
      onClick={() => history.push(`/promotion/wheel`)}
    >
      <SuiBox
        component="img"
        src={LuckyWheel}
        alt="message-image"
        borderRadius="lg"
        boxShadow="md"
        width="100%"
        display="inherit"
      />
    </SuiBox>
  );

  const lineButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3rem"
      height="3rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      right="0.75rem"
      bottom={user && user?.token ? "5.5rem" : "0.75rem"}
      zIndex={99}
      customClass="cursor-pointer"
      onClick={() => window.open(`${process.env.REACT_APP_LINE_URL}`, "_blank")}
    >
      <SuiBox
        component="img"
        src={LINEFAB}
        alt="message-image"
        borderRadius="lg"
        boxShadow="md"
        width="100%"
        display="inherit"
      />
    </SuiBox>
  );

  const AnnouncementPopup = (
    <SuiBox display="flex" justifyContent="center" alignItems="center">
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
        fullWidth
        open={true}
      >
        <Card>
          <SuiBox>
            <DialogContent>
              <SuiBox textAlign="center">
                <SuiTypography>ประกาศปิดปรับปรุงระบบ</SuiTypography>
                <SuiTypography>ตั้งแต่เวลา 17.00 น. ถึง 24.00 น.</SuiTypography>
              </SuiBox>
            </DialogContent>
          </SuiBox>
        </Card>
      </Dialog>
    </SuiBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          {/* {user?.token && wheelButton} */}
          {/* {AnnouncementPopup} */}
          {lineButton}
          {layout === "dashboard" && (
            <>
              <Sidenav
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Switch>
            {getRoutes(routes)}
            {!user || !user.token || user.token === "" ? (
              <Redirect from="*" to="/sign-in" />
            ) : (
              <Redirect from="*" to="/" />
            )}

            {/* <Redirect from="*" to="/dashboards" /> */}
          </Switch>
        </ThemeProvider>
      </StylesProvider>
    </CacheProvider>
  ) : (
    // </CacheProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* {user?.token && wheelButton} */}
        {/* {AnnouncementPopup} */}
        {lineButton}
        {layout === "dashboard" && (
          <>
            <Sidenav
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Switch>
          {getRoutes(routes)}
          {!user || !user.token || user.token === "" ? (
            <Redirect from="*" to="/sign-in" />
          ) : (
            <Redirect from="*" to="/" />
          )}

          {/* <Redirect from="*" to="/dashboards" /> */}
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
