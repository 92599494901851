/* eslint-disable */
import { useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import { Card, Grid, Modal, Typography } from "@mui/material";
import Swal from "sweetalert2";
import breakpoints from "assets/theme/base/breakpoints";

import PropTypes from "prop-types";
import { PGs } from "utils/pg-list";
import { XGs } from "utils/xg-list";
import SuiTypography from "components/SuiTypography";
import { xgGameUrl } from "api/member";
import SuiAvatar from "components/SuiAvatar";
import ComplexBackgroundCard from "./ComplexBackgroundCard";
import { RingLoader } from "react-spinners";

function PGAgent({ game }) {
  const [mobileView, setMobileView] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [gameList, setGameList] = useState([]);

  async function getLobbyUrl(gameCode) {
    // const newWindow = window.open("about:blank", "_blank");
    setLoading(true);
    try {
      const gameLobby = await xgGameUrl({ gameCode: gameCode });
      setLoading(false);
      if (gameLobby?.data?.status === "success") {
        const loginUrl = await gameLobby?.data?.data?.url;
        console.log("getLobbyUrl", loginUrl.toString());

        if (loginUrl) {
          // window.open(loginUrl, "_self");
          // window.open("data:text/html;charset=utf-8," + loginUrl.toString(), "", "_self");
          // window.open(document.write(loginUrl.toString(), "_self"));

          const data = (await "data:text/plain;charset=utf-8,") + loginUrl;
          var myWindow = window.open("", "_self");
          myWindow.document.write(data);
          setTimeout(() => window.URL.revokeObjectURL(data), 100);

          setLoading(false);
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // window.close();
      console.log("getLobbyUrl-catch-error", err);
      console.log("getLobbyUrl-catch-error-response", err.response);
    }
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }

    if (game === "pg") {
      setGameList(PGs);
    }
    if (game === "xg") {
      setGameList(XGs);
    }
  }, [game]);

  console.log("PGs", PGs.length);

  return (
    <SuiBox>
      <Modal
        open={isLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuiBox
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <RingLoader color="#17c1e8" />
        </SuiBox>
      </Modal>
      <Grid container spacing={1} justifyContent="center" px={1}>
        {gameList &&
          gameList.length > 0 &&
          gameList.map((g, i) => {
            return (
              <Grid item xs={mobileView ? 4 : 3}>
                <SuiBox
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => getLobbyUrl(g.gameCode)}
                >
                  <SuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="rgba(0, 0, 0, 0.3)"
                    backgroundGradient
                    borderRadius="lg"
                  >
                    <SuiBox display="flex" justifyContent="center">
                      {/* <ComplexBackgroundCard image={g.gameThumbnail} description={g.gameName} /> */}
                      {/* <SuiAvatar
                        src={g.gameThumbnail}
                        alt={game}
                        size="xxl"
                        variant="rounded"
                        boxShadow="regular"
                      /> */}
                      <Card>
                        <SuiBox component="img" src={g.gameThumbnail} alt={game} width="100%" />
                        <SuiBox
                          sx={{ position: "absolute" }}
                          display="flex"
                          flex={1}
                          width="100%"
                          height="100%"
                          alignItems="flex-end"
                        >
                          <SuiBox
                            backgroundColor="rgba(0, 0, 0, 0.5)"
                            width="100%"
                            justifyContent="center"
                            display="flex"
                          >
                            {/* <Typography inline>{g.gameName}</Typography> */}
                            <SuiTypography
                              noWrap
                              variant="body2"
                              textColor="white"
                              fontWeight="bold"
                            >
                              {g.gameName}
                            </SuiTypography>
                          </SuiBox>
                        </SuiBox>
                      </Card>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </Grid>
            );
          })}
      </Grid>
    </SuiBox>
  );
}

PGAgent.defaultProps = {};

PGAgent.propTypes = {};

export default PGAgent;
