// import Default from "layouts/dashboards/default";

import WheelComponent from "components/Wheel/wheel-component";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import Default from "layouts/dashboards/default";
import PromotionPage from "layouts/dashboards/promotion/promotionPage";
import Deposit from "layouts/deposit";
import Withdraw from "layouts/withdraw/withdraw";

// import MainPage from "layouts/pages/main-page";

const routes = [
  {
    name: "Dashboards",
    key: "dashboards",
    route: "/",
    component: Default,
    protected: true,
  },
  {
    name: "ฝากเงิน",
    key: "deposit",
    route: "/deposit",
    component: Deposit,
    protected: true,
  },
  {
    name: "ถอนเงิน",
    key: "withdraw",
    route: "/withdraw",
    component: Withdraw,
  },
  {
    name: "เข้าสู่ระบบ",
    key: "authentication",
    route: "/sign-in",
    component: SignInBasic,
  },
  {
    name: "สมัครสมาชิก",
    key: "authentication",
    route: "/sign-up",
    component: SignUpBasic,
  },
  {
    name: "โปรโมชั่น",
    key: "promotion",
    route: "/promotions",
    component: PromotionPage,
  },
  {
    name: "กงล้อ",
    key: "wheel",
    route: "/promotion/wheel",
    component: WheelComponent,
  },
  // {
  //   name: "HOME",
  //   key: "home",
  //   route: "/",
  //   component: MainPage,
  // },
];

export default routes;
