import axios from "./index";

const base = "member";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const MemberList = (data) => axios.post(`${base}/memberlist`, data);
// export const FindMember = (data) => axios.post(`${base}/findmember`, data);

export const memberlogin = async (data) => {
  try {
    const response = await axios.post(`/login`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberList = async (data) => {
  try {
    const response = await axios.post(`${base}/memberlist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const FindMember = async (data) => {
  try {
    const response = await axios.post(`${base}/findmember`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CheckBalance = async (data) => {
  try {
    const response = await axios.post(`${base}/checkbalance`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CheckBetflixBalance = async (data) => {
  try {
    const response = await axios.post(`${base}/betflixbalance`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GameLobbyURL = async (data) => {
  try {
    const response = await axios.post(`${base}/gamelobby`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const startUfa = async (data) => {
  try {
    const response = await axios.post(`${base}/startufa`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const startBetflix = async (data) => {
  try {
    const response = await axios.post(`${base}/startbetflix`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const startLottery = async (data) => {
  try {
    const response = await axios.post(`${base}/startlottery`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const transactionHistory = async (data) => {
  try {
    const response = await axios.post(`${base}/history`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const depositTransaction = async (data) => {
  try {
    const response = await axios.post(`${base}/depositHistory`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const withdrawTransaction = async (data) => {
  try {
    const response = await axios.post(`${base}/withdrawHistory`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const friendReferral = async (data) => {
  try {
    const response = await axios.post(`${base}/friendReferral`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const Commission = async (data) => {
  try {
    const response = await axios.post(`${base}/commission`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberChangePassword = async (data) => {
  try {
    const response = await axios.post(`${base}/change_password`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const xgGameUrl = async (data) => {
  try {
    const response = await axios.post(`${base}/launch`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
