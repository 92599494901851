/* eslint-disable */
import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Box, Divider, Grid } from "@mui/material";
import Swal from "sweetalert2";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";

import { useAuth } from "auth-context/auth.context";
import logonavbar from "assets/png/logonavbar.png";
// import bflable from "assets/png/bflabel.png";
// import ufalabel from "assets/png/ufalabel.png";
// import huaylabel from "assets/png/huaylabel.png";
import VerifyPhoneNumber from "./varify-phone-number";
import SetPassword from "./set-password";
import MemberInfoForm from "./member-info-form";
import { Register } from "../../../../api/auth";
const API_SERVER =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8080/";

function RegisterPage() {
  const [member, setMember] = useState();
  const [error, setError] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const { login } = useAuth();
  const history = useHistory();

  const handleAutoLogin = async (data) => {
    Swal.fire({
      title: "กำลังเข้าสู่ระบบ ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const loginR = await login({ username: data?.phone_number, password: data?.password });
      console.log("register-page - loginR", loginR);
      if (loginR?.data?.status === "success") {
        setMember();
        Swal.close();
        const user = loginR?.data?.data?.user;
        const accessToken = user?.token;
        console.log("register-page - user", user);
        console.log("register-page - accessToken", accessToken);
        window.localStorage.setItem("user", JSON.stringify(user));
        window.localStorage.setItem("accessToken", accessToken);

        history.push("/");
        window.location.reload();
      } else {
        setMember();
        Swal.close();
        history.push(`/sign-in`);
      }
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const newMember = { ...member };
    if (!member?.first_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.last_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.password) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.phone_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.bank_uid) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.member_bank_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.campaign_uid) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    if (window?.location?.search !== "") {
      const wls = window?.location?.search;
      const spwls = wls.split("?ref=");
      const friendRef = spwls[1];

      newMember.friendRef = friendRef;
      setMember(newMember);
    }
    console.log("newMember", newMember);

    Swal.fire({
      title: "กำลังสมัครสมาชิก ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await Register(newMember);
      console.log("register-response", response);
      if (response?.data?.status === "success") {
        Swal.fire({
          title: "สมัครสมาชิกสำเร็จ",
          text: "รหัสสำหรับเข้าใช้งาน",
          icon: "success",
          html: `ยูสเซอร์: <b>${member?.phone_number}</b><br/>รหัส: <b>${member?.password}</b>`,
          showCancelButton: false,
          confirmButtonText: "ปิด",
          reverseButtons: true,
          allowOutsideClick: false,
          preConfirm: () => {
            const data = { phone_number: member?.phone_number, password: member?.password };
            handleAutoLogin(data);
          },
        });
      } else {
        Swal.close();
        ResponseAlert(response);
      }
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  function handleSetMember(key, value) {
    const newMember = { ...member };
    newMember[key] = value;
    setMember(newMember);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <VerifyPhoneNumber
            onSetPhoneNumber={(value) => {
              handleSetMember("phone_number", value);
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 1:
        return (
          <SetPassword
            onSetPassword={(value) => {
              handleSetMember("password", value);
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 2:
        return <MemberInfoForm onSetMember={(m) => setMember({ ...member, ...m })} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    const imageUrl = `${API_SERVER}images/promotion/new_member.jpg`;

    fetch(imageUrl)
      .then((response) => {
        if (response.ok) {
          setImgUrl(imageUrl);
        } else {
          console.log("Image URL is invalid");
        }
      })
      .catch((error) => {
        console.error("Error validating image URL:", error);
      });
  }, []);

  return (
    <SuiBox display="flex" flexDirection="column" height="100%">
      <Box sx={{ backgroundColor: "#0000002e" }}>
        <SuiBox display="flex" justifyContent="center">
          <SuiBox component="img" src={logonavbar} alt="logonavbar" boxShadow="md" height="7vh" />
        </SuiBox>
      </Box>

      <SuiBox display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Grid sx={{}} container justifyContent="center">
          {imgUrl && (
            <Grid item xs={12} lg={6}>
              <SuiBox display="flex" justifyContent="center" alignItems="center">
                <SuiBox
                  component="img"
                  src={imgUrl}
                  alt="new_member"
                  borderRadius="lg"
                  width="70%"
                />
              </SuiBox>
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Card
                className="overflow-visible"
                style={{
                  backgroundColor: "#0000002e",
                  width: "100%",
                  padding: 15,
                }}
              >
                <SuiBox textAlign="center">
                  <SuiTypography variant="h5" fontWeight="medium" textColor="white">
                    สมัครสมาชิก (Register)
                  </SuiTypography>
                </SuiBox>
                {/* <Divider /> */}
                <SuiBox px={3}>
                  {getStepContent(activeStep)}
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6
                      style={{
                        fontSize: ".7em",
                        color: "red",
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}
                    >
                      {error}
                    </h6>
                  </SuiBox>
                  {activeStep === 2 && (
                    <SuiBox mt={3} display="flex" justifyContent="flex-end">
                      <SuiButton
                        onClick={register}
                        variant="gradient"
                        buttonColor="white"
                        size="small"
                      >
                        สมัครสมาชิก (Register)
                      </SuiButton>
                    </SuiBox>
                  )}

                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" textColor="white" fontWeight="regular">
                      มีบัญชีอยู่แล้ว?&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/sign-in/basic"
                        variant="button"
                        textColor="white"
                        fontWeight="bold"
                        // textGradient
                      >
                        เข้าสู่ระบบ
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {/* <SuiBox display="flex" justifyContent="center" height="10vh">
        <SuiBox component="img" src={logonavbar} alt="logonavbar" boxShadow="md" height="10vh" />
      </SuiBox> */}
    </SuiBox>
  );
}

export default RegisterPage;
